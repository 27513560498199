var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4 bg-white p-8" },
    [
      _c("h4", [
        _vm._v(_vm._s(_vm.$t("resorts.touristic-objects.form.mainTitle")))
      ]),
      _c("p", { staticClass: "text-sm text-dark mb-8" }, [
        _vm._v(_vm._s(_vm.$t("resorts.touristic-objects.form.mainSubtitle")))
      ]),
      _c(
        "div",
        { staticClass: "flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2" },
        [
          _vm.isAdmin
            ? _c("vs-input", {
                staticClass: "border-grey-light ",
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "resorts.touristic-objects.form.resortReference"
                  ),
                  required: ""
                },
                model: {
                  value: _vm.touristicObject.resort_reference,
                  callback: function($$v) {
                    _vm.$set(_vm.touristicObject, "resort_reference", $$v)
                  },
                  expression: "touristicObject.resort_reference"
                }
              })
            : _vm._e(),
          _c(
            "vs-checkbox",
            {
              staticClass: "ml-0 mb-4 mt-4 text-md font-bold text-dark",
              model: {
                value: _vm.touristicObject.published,
                callback: function($$v) {
                  _vm.$set(_vm.touristicObject, "published", $$v)
                },
                expression: "touristicObject.published"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("resorts.touristic-objects.form.published")) +
                  " "
              )
            ]
          ),
          _vm.touristicObjectCategory === "events"
            ? _c(
                "div",
                { staticClass: "flex flex-row gap-4" },
                [
                  _c("DatePicker", {
                    attrs: {
                      label:
                        _vm.$t("resorts.touristic-objects.form.startDate") +
                        " *"
                    },
                    model: {
                      value: _vm.startDateObject,
                      callback: function($$v) {
                        _vm.startDateObject = $$v
                      },
                      expression: "startDateObject"
                    }
                  }),
                  _c("DatePicker", {
                    attrs: {
                      label: _vm.$t("resorts.touristic-objects.form.endDate")
                    },
                    model: {
                      value: _vm.endDateObject,
                      callback: function($$v) {
                        _vm.endDateObject = $$v
                      },
                      expression: "endDateObject"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-select",
            {
              staticClass: "border-grey-light pb-2",
              attrs: { required: "", label: _vm.$t("timeofyear") },
              model: {
                value: _vm.touristicObject.timeofyear,
                callback: function($$v) {
                  _vm.$set(_vm.touristicObject, "timeofyear", $$v)
                },
                expression: "touristicObject.timeofyear"
              }
            },
            _vm._l(_vm.timeofyearOptions, function(option) {
              return _c("vs-select-item", {
                key: option.key,
                attrs: {
                  value: option.key,
                  text: _vm._f("capitalize")(option.label)
                }
              })
            }),
            1
          ),
          _c("selectionExpandable", {
            staticClass: "mt-4",
            attrs: { selections: _vm.touristicObject.selections }
          }),
          _c("h6", { staticClass: "mt-8" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("resorts.touristic-objects.form.contentLanguage"))
            )
          ]),
          _c(
            "div",
            { staticClass: "w-1/2 mt-2" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "border-grey-light w-full pb-3",
                  attrs: {
                    required: "",
                    label: _vm.$t(
                      "resorts.touristic-objects.form.mainLanguagesInput"
                    ),
                    placeholder: _vm.$t(
                      "resorts.touristic-objects.form.defaultLanguage"
                    )
                  },
                  model: {
                    value: _vm.defaultLanguage,
                    callback: function($$v) {
                      _vm.defaultLanguage = $$v
                    },
                    expression: "defaultLanguage"
                  }
                },
                _vm._l(_vm.spotlioLanguages, function(option) {
                  return _c("vs-select-item", {
                    key: option.id,
                    attrs: { value: option.id, text: option.name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "w-1/2 mt-2" }, [
            _vm.zones || _vm.touristicObjectCategory !== "events"
              ? _c("h6", { staticClass: "mb-5" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("resorts.touristic-objects.form.segmentation")
                      )
                  )
                ])
              : _vm._e(),
            _vm.touristicObjectCategory !== "events"
              ? _c(
                  "div",
                  [
                    _c(
                      "vs-select",
                      {
                        staticClass: "border-grey-light w-full",
                        attrs: {
                          label: _vm.$t(
                            "resorts.touristic-objects.form.typeInput"
                          ),
                          placeholder: _vm.$t(
                            "resorts.touristic-objects.form.type"
                          )
                        },
                        on: { change: _vm.changeType },
                        model: {
                          value: _vm.touristicObjectType,
                          callback: function($$v) {
                            _vm.touristicObjectType = $$v
                          },
                          expression: "touristicObjectType"
                        }
                      },
                      _vm._l(_vm.types, function(option) {
                        return _c("vs-select-item", {
                          key: option.id,
                          attrs: { value: option.id, text: option.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.zones
              ? _c(
                  "div",
                  [
                    _c(
                      "vs-select",
                      {
                        staticClass: "border-grey-light w-full",
                        attrs: {
                          label: _vm.$t(
                            "resorts.touristic-objects.form.zoneInput"
                          ),
                          required: "",
                          placeholder: _vm.$t(
                            "resorts.touristic-objects.form.zone"
                          )
                        },
                        model: {
                          value: _vm.touristicObjectZone,
                          callback: function($$v) {
                            _vm.touristicObjectZone = $$v
                          },
                          expression: "touristicObjectZone"
                        }
                      },
                      _vm._l(_vm.zones, function(option) {
                        return _c("vs-select-item", {
                          key: option.uuid,
                          attrs: { value: option.uuid, text: option.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.touristicObject.default_language
        ? _c("LocalizedContent", {
            ref: "localizedContent",
            attrs: {
              fixedLanguage: _vm.spotlioLanguages.find(function(lang) {
                return lang.id === _vm.touristicObject.default_language.id
              }).acronym,
              fieldsDefinition: [
                {
                  name: "name",
                  label: _vm.$t("resorts.touristic-objects.form.name"),
                  type: "text",
                  maxLength: 50
                },
                {
                  name: "highlight",
                  label: _vm.$t("resorts.touristic-objects.form.highlight"),
                  type: "text",
                  maxLength: 50
                },
                {
                  name: "address",
                  label: _vm.$t("resorts.touristic-objects.form.address"),
                  type: "text",
                  maxLength: 50
                },
                {
                  name: "phone",
                  label: _vm.$t("resorts.touristic-objects.form.phone1"),
                  type: "text",
                  maxLength: 20
                },
                {
                  name: "other_phone",
                  label: _vm.$t("resorts.touristic-objects.form.phone2"),
                  type: "text",
                  maxLength: 20
                },
                {
                  name: "email",
                  label: _vm.$t("resorts.touristic-objects.form.email1"),
                  type: "text",
                  maxLength: 100
                },
                {
                  name: "other_email",
                  label: _vm.$t("resorts.touristic-objects.form.email2"),
                  type: "text",
                  maxLength: 100
                },
                {
                  name: "web_link",
                  label: _vm.$t("resorts.touristic-objects.form.website"),
                  type: "text",
                  maxLength: 65535
                },
                {
                  name: "reservation_link",
                  label: _vm.$t(
                    "resorts.touristic-objects.form.reservationLink"
                  ),
                  type: "text",
                  maxLength: 65535
                },
                {
                  name: "rates",
                  label: _vm.$t("resorts.touristic-objects.form.ratesTitle"),
                  type: "textarea",
                  maxLength: 65535
                },
                {
                  name: "description",
                  label: _vm.$t(
                    "resorts.touristic-objects.form.descriptionTitle"
                  ),
                  type: "textarea",
                  maxLength: 65535
                },
                {
                  name: "description_html",
                  label: _vm.$t(
                    "resorts.touristic-objects.form.descriptionTitle"
                  ),
                  type: "rich_textarea",
                  maxLength: 65535
                },
                {
                  name: "schedule",
                  label: _vm.$t("resorts.touristic-objects.form.scheduleTitle"),
                  type: "textarea",
                  maxLength: 65535
                },
                {
                  name: "services_tags",
                  label: _vm.$t("resorts.touristic-objects.form.services"),
                  type: "tags"
                },
                {
                  name: "equipments_tags",
                  label: _vm.$t("resorts.touristic-objects.form.equipments"),
                  type: "tags"
                },
                {
                  name: "comfort_tags",
                  label: _vm.$t("resorts.touristic-objects.form.comfort"),
                  type: "tags"
                },
                {
                  name: "accessibility_tags",
                  label: _vm.$t("resorts.touristic-objects.form.accessibility"),
                  type: "tags"
                },
                {
                  name: "payments_mode_tags",
                  label: _vm.$t("resorts.touristic-objects.form.payments"),
                  type: "tags"
                }
              ].filter(function(localizedAttr) {
                return _vm.isLocalizedAttributeVisible(localizedAttr.name)
              })
            },
            model: {
              value: _vm.localizedContent,
              callback: function($$v) {
                _vm.localizedContent = $$v
              },
              expression: "localizedContent"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "mt-4 flex ml-0 flex-column w-full md:w-2/3" }, [
        _c(
          "div",
          { staticClass: "flex flex-row flex-wrap w-full md:w-2/3" },
          [
            _vm.isAttributeVisible("min_height")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 md:pr-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.min_height,
                    label:
                      _vm.$t("resorts.touristic-objects.form.min_height") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "depth",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.min_height,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "min_height", $$v)
                    },
                    expression: "touristicObject.min_height"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("max_height")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.max_height,
                    label:
                      _vm.$t("resorts.touristic-objects.form.max_height") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "depth",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.max_height,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "max_height", $$v)
                    },
                    expression: "touristicObject.max_height"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex flex-row flex-wrap w-full md:w-2/3" },
          [
            _vm.isAttributeVisible("start_height")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 md:pr-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.start_height,
                    label:
                      _vm.$t("resorts.touristic-objects.form.start_height") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "height",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.start_height,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "start_height", $$v)
                    },
                    expression: "touristicObject.start_height"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("end_height")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.end_height,
                    label:
                      _vm.$t("resorts.touristic-objects.form.end_height") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "height",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.end_height,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "end_height", $$v)
                    },
                    expression: "touristicObject.end_height"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex flex-row flex-wrap w-full md:w-2/3" },
          [
            _vm.isAttributeVisible("total_ascent")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 md:pr-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.total_ascent,
                    label:
                      _vm.$t("resorts.touristic-objects.form.total_ascent") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "height",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.total_ascent,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "total_ascent", $$v)
                    },
                    expression: "touristicObject.total_ascent"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("total_descent")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.total_descent,
                    label:
                      _vm.$t("resorts.touristic-objects.form.total_descent") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "height",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.total_descent,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "total_descent", $$v)
                    },
                    expression: "touristicObject.total_descent"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full md:w-2/3" },
          [
            _vm.isAttributeVisible("length")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    step: "0.01",
                    value: _vm.touristicObject.length,
                    label:
                      _vm.$t("resorts.touristic-objects.form.length") +
                      " (" +
                      this.$options.filters.unit_system_converter(
                        "distance",
                        this.referenceSystem
                      ) +
                      ")"
                  },
                  model: {
                    value: _vm.touristicObject.length,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "length", $$v)
                    },
                    expression: "touristicObject.length"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("time")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    value: _vm.touristicObject.time,
                    label: _vm.$t("resorts.touristic-objects.form.time")
                  },
                  model: {
                    value: _vm.touristicObject.time,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "time", $$v)
                    },
                    expression: "touristicObject.time"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("min_age")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    value: _vm.touristicObject.min_age,
                    label: _vm.$t("resorts.touristic-objects.form.min_age")
                  },
                  model: {
                    value: _vm.touristicObject.min_age,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "min_age", $$v)
                    },
                    expression: "touristicObject.min_age"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("tickets")
              ? _c("vs-input", {
                  staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                  attrs: {
                    type: "number",
                    min: "0",
                    value: _vm.touristicObject.tickets,
                    label: _vm.$t("resorts.touristic-objects.form.tickets")
                  },
                  model: {
                    value: _vm.touristicObject.tickets,
                    callback: function($$v) {
                      _vm.$set(_vm.touristicObject, "tickets", $$v)
                    },
                    expression: "touristicObject.tickets"
                  }
                })
              : _vm._e(),
            _vm.isAttributeVisible("subtype") && _vm.subtypes.length > 0
              ? _c(
                  "vs-select",
                  {
                    staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                    attrs: {
                      label: _vm.$t("resorts.touristic-objects.form.subtype"),
                      required: ""
                    },
                    model: {
                      value: _vm.touristicObject.subtype_id,
                      callback: function($$v) {
                        _vm.$set(_vm.touristicObject, "subtype_id", $$v)
                      },
                      expression: "touristicObject.subtype_id"
                    }
                  },
                  [
                    _c("vs-select-item", { attrs: { value: null, text: "-" } }),
                    _vm._l(this.subtypes, function(option) {
                      return _c("vs-select-item", {
                        key: option.id,
                        attrs: { value: option.id, text: option.name }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.isAttributeVisible("ride_thrill")
              ? _c(
                  "vs-select",
                  {
                    staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                    attrs: {
                      label: _vm.$t(
                        "resorts.touristic-objects.form.ride_thrill"
                      )
                    },
                    model: {
                      value: _vm.rideThrill,
                      callback: function($$v) {
                        _vm.rideThrill = $$v
                      },
                      expression: "rideThrill"
                    }
                  },
                  [
                    _c("vs-select-item", { attrs: { value: null, text: "-" } }),
                    _vm._l(_vm.rideThrills, function(option) {
                      return _c("vs-select-item", {
                        key: option.id,
                        attrs: { value: option.id, text: option.key }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.isAttributeVisible("ride_audience")
              ? _c(
                  "vs-select",
                  {
                    staticClass: "border-grey-light pb-2 w-full md:w-1/2",
                    attrs: {
                      label: _vm.$t(
                        "resorts.touristic-objects.form.ride_audience"
                      )
                    },
                    model: {
                      value: _vm.rideAudience,
                      callback: function($$v) {
                        _vm.rideAudience = $$v
                      },
                      expression: "rideAudience"
                    }
                  },
                  [
                    _c("vs-select-item", { attrs: { value: null, text: "-" } }),
                    _vm._l(_vm.rideAudiences, function(option) {
                      return _c("vs-select-item", {
                        key: option.id,
                        attrs: { value: option.id, text: option.key }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.isAttributeVisible("requirements")
              ? _c(
                  "div",
                  [
                    _c("label", { staticClass: "text-sm pl-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("resorts.touristic-objects.form.requirements")
                        ) + " "
                      )
                    ]),
                    _c("v-select", {
                      staticClass: "border-grey-light v-selector",
                      attrs: {
                        multiple: "",
                        placeholder: _vm.$t(
                          "resorts.touristic-objects.form.requirements"
                        ),
                        options: _vm.requirements,
                        label: "key"
                      },
                      model: {
                        value: _vm.touristicObject.requirements,
                        callback: function($$v) {
                          _vm.$set(_vm.touristicObject, "requirements", $$v)
                        },
                        expression: "touristicObject.requirements"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isAttributeVisible("facility_difficulty")
              ? _c(
                  "div",
                  { staticClass: "w-full mt-2" },
                  [
                    _c("label", { staticClass: "text-sm w-full pl-1" }, [
                      _vm._v(
                        _vm._s(this.$t("facilities.tableheader.difficulty")) +
                          " "
                      )
                    ]),
                    _c("v-select", {
                      staticClass:
                        "bg-white search-select rounded-lg w-full md:w-1/2",
                      attrs: {
                        options: _vm.facilityDifficultySelect,
                        appendToBody: true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function(option) {
                              return [
                                option.icon
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "w-8 inline-block text-center"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "w-6",
                                          attrs: { src: option.icon }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(option.label) + " ")
                              ]
                            }
                          },
                          {
                            key: "selected-option",
                            fn: function(option) {
                              return [
                                option.icon
                                  ? _c("span", { staticClass: "w-8" }, [
                                      _c("img", {
                                        staticClass: "w-6",
                                        attrs: { src: option.icon }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(option.label) + " ")
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2712792283
                      ),
                      model: {
                        value: _vm.facilityDifficulty,
                        callback: function($$v) {
                          _vm.facilityDifficulty = $$v
                        },
                        expression: "facilityDifficulty"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.touristicObject
        ? _c(
            "div",
            { staticClass: "mt-4 flex ml-0 flex-column w-full" },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("resorts.touristic-objects.form.addImage"))
                )
              ]),
              _c("p", { staticClass: "text-sm text-dark" }, [
                _vm._v(
                  _vm._s(_vm.$t("resorts.touristic-objects.form.addImageSub"))
                )
              ]),
              _c("ImagesForm", {
                attrs: {
                  images: _vm.touristicObject.image_list,
                  deletedImages: _vm.deletedImages,
                  allowedExtensions: ["image/jpg", "image/jpeg"],
                  id: "selectToImage",
                  validationWidth: 1080,
                  validationHeight: 575,
                  validationWeight: 5500,
                  validationMessage: _vm.$t("banner.form.imageerror"),
                  width: "240",
                  height: "170",
                  name: "selectedImages"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-4 flex ml-0 flex-column w-full" },
        [
          _c(
            "vs-checkbox",
            {
              staticClass: "my-10 checkboxform border-0 mx-0",
              model: {
                value: _vm.hasCoordinates,
                callback: function($$v) {
                  _vm.hasCoordinates = $$v
                },
                expression: "hasCoordinates"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.$t("resorts.touristic-objects.form.need_coords")
                  ) +
                  " "
              )
            ]
          ),
          _vm.hasCoordinates
            ? _c(
                "div",
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        this.$t("resorts.touristic-objects.form.locateMap")
                      )
                    )
                  ]),
                  _c("p", { staticClass: "text-sm text-dark" }, [
                    _vm._v(
                      _vm._s(
                        this.$t("resorts.touristic-objects.form.locateMapSub")
                      )
                    )
                  ]),
                  _c("MapCoordinates", {
                    attrs: {
                      defaultCoordinates: _vm.defaultCoordinates,
                      componentSize: _vm.mapSize,
                      zoom: 18
                    },
                    on: {
                      updated: _vm.handleMapChange,
                      invalidCoords: _vm.handleInvalidCoords
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center ml-0 gap-4 mt-5" },
        [
          _c(
            "vs-button",
            {
              staticClass: "rounded-lg xs:w-full sm:w-auto ml-2 ",
              on: { click: _vm.save }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("resorts.touristic-objects.form.saveButton")) +
                  " "
              )
            ]
          ),
          _c(
            "vs-button",
            {
              staticClass: "rounded-lg xs:w-full sm:w-auto text-primary",
              attrs: { type: "border" },
              on: { click: _vm.cancel }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("resorts.touristic-objects.form.cancelButton")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }