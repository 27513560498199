<template>
    <div>
        <div class="flex flex-wrap mb-3">
            <div v-for="(image, index) in images" :key="index" class="shadow-md m-3 rounded">

                <img :src="image.url" :style="{ width: width + 'px', height: height + 'px' }"
                    class=" object-cover  rounded-tl rounded-tr">
                <div class="image-actions  flex justify-end"
                    :style="{ width: width + 'px', height: (height / 3.7) + 'px' }">
                    <div class="w-50 ">
                        <p v-if="image.main" class="float-left text-sm mt-3"> <b>Main image</b></p>
                        <p v-else class="text-center"> </p>


                    </div>
                    <vs-button type="border" class="text-primary px-0 py-0 rounded-lg">
                        <feather-icon icon="Maximize2Icon" svgClasses="text-primary w-6" class="mt-0"
                            @click="maximize(image)" />
                    </vs-button>
                    <vs-button v-if="editMode" type="border" class="text-primary  px-0 py-0  rounded-lg">
                        <feather-icon icon="TrashIcon" svgClasses="text-primary  w-6" class="mt-0"
                            @click="handleDelete(index)" />
                    </vs-button>

                </div>
            </div>
        </div>

        <vs-input type="file" :name="name" hidden :id="id" @change="onFileChange" :danger="validateError"
            :danger-text="validationMessage" multiple />

        <div v-if="editMode" class="flex ">
            <div class="mt-3 flex">
                <vs-button :disabled="disabled" class="button-select-image  inline-block rounded-lg mr-2 p-3"
                    @click="handlerChooseFiles()">{{ $t('resorts.touristic-objects.form.selectImage') }}</vs-button>
                <vs-button type="border" class="button-select-image  inline-block rounded-lg mr-2 p-3"
                    @click="deleteAllImages()">{{ $t('resorts.touristic-objects.form.deleteImages') }}</vs-button>
            </div>

        </div>

        <p class="text-xs text-grey mt-2"> {{ $t('resorts.touristic-objects.form.imageSpecification') }}</p>

        <vs-popup class="modalMap" v-model="isModalOpen" :active.sync="isModalOpen" title="">
            <img :src="maximizedImage.url" :style="{ maxWidth: '100%', maxHeight: '100%' }">
        </vs-popup>

    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        'id': String,
        'name': String,
        'alt': String,
        'width': String,
        'height': String,
        'validationWidth': Number,
        'validationHeight': Number,
        allowedExtensions: {
            type: Array,
            required: false,
            default() {
                return ["image/jpeg", "image/png", "image/jpg"]
            }
        },
        'validationWeight': Number,
        'validationMessage': String,
        'constraintsText': String,
        'disabled': {
            'type': Boolean,
            'default': false
        },
        'editMode': {
            'type': Boolean,
            'default': true
        },
        images: {
            type: Array,
            required: true,
            default: () => []
        },
        deletedImages: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            isModalOpen: false,
            maximizedImage: {},
            validateError: false,
        }
    },
    methods: {
        handlerChooseFiles() {
            document.getElementById(this.id).click()
        },
        async onFileChange(e) {
            let totalFiles = e.target.files.length
            for (let i = 0; i < totalFiles; i++) {
                const file = e.target.files.item(i);
                const image = URL.createObjectURL(file);

                const img = new Image();
                const that = this;

                const imageInBase64 = await this.convertToBase64(file).then(base64 => {
                    return base64
                });
                img.src = image;
                img.onload = (function () {
                    console.log(that.validationWidth != this.width, that.validationWidth, this.width, that.validationHeight, this.height, file.size / 1024, that.validationWeight)
                    const validationWeightError = that.validationWeight ? that.validationWeight < file.size / 1024 : false;
                    const validationWidthError = that.validationWidth ? that.validationWidth != this.width : false;
                    const validationHeightError = that.validationHeight ? that.validationHeight != this.height : false;
                    const mimeError = !that.allowedExtensions.includes(file.type)
                    console.log(validationWeightError, validationWidthError, validationHeightError, mimeError)
                    let errorCondition = validationWeightError || validationWidthError || validationHeightError || mimeError
                    if (errorCondition) {
                        that.validateError = true
                    } else {
                        if (that.images.length == 0) {
                            that.images.push({ uuid: uuidv4(), url: img.src, file: file, base64content: imageInBase64, main: true, new: true });
                        } else {
                            that.images.push({ uuid: uuidv4(), url: img.src, file: file, base64content: imageInBase64, main: false, new: true });
                        }
                        that.validateError = false;
                    }
                })
            }
        },
        handleDelete(index) {
            this.deletedImages.push(this.images[index]);
            this.images.splice(index, 1)
            this.images[0].main = true;
        },
        deleteAllImages() {
            this.images.forEach(image => {
                this.deletedImages.push(image)
            });
            this.images.splice(0, this.images.length)
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        },
        maximize(image) {
            this.maximizedImage = image
            this.isModalOpen = true
        },
    },
};
</script>

<style scoped>
.image-actions * {
    margin: 5px
}
</style>