<template>
    <div class="mt-4">

        <div v-if="mapCoordinates && mapCoordinates.length > 0"  class="flex flex-row mt-4 gap-2">
            <label  class="text-sm w-2/12 ">{{ $t("geofences.add-geofences.latitude") }} </label>
            <label  class="text-sm w-2/12">{{ $t("geofences.add-geofences.longitude") }} </label>
            <label  class="text-sm w-2/12">{{ $t("resorts.touristic-objects.form.height") }}</label>
        </div>


        <div v-for="(option,index) in this.mapCoordinates" :key="index" class="flex flex-row gap-2">
            <vs-input class="w-2/12"
                      type="number"
                      :value="option.lat || 0 "
                      v-model="option.lat"
                      :disabled="disabled"
            />

            <vs-input class="w-2/12 "
                      type="number"
                      :value="option.lng || 0 "
                      v-model="option.lng"
                      :disabled="disabled"
            />

            <vs-input class="w-2/12 "
                      type="number"
                      :value="option.height || 0 "
                      v-model="option.height"
                      :disabled="disabled"
            />

            <vs-button v-if="!disabled" type="border" color="rgb(255, 0, 0)"  @click="deleteRow(index)"  class="px-2 pt-1 pb-0 mb-5 mr-2 rounded-lg ">
                <feather-icon icon="TrashIcon" svgClasses=" align-middle w-6"/>
            </vs-button>
        </div>
        <div class="w-2/3 mt-5">
            <vs-button v-if="!disabled"
                type="border"
                class="rounded-lg xs:w-full sm:w-auto text-primary"
                @click="add">
                {{ this.$t('resorts.touristic-objects.form.add_point') }}
            </vs-button>
        </div>
    </div>
</template>

<script>
    import InputCharCounter from "@/modules/Shared/Components/form/InputCharCounter.vue";

    export default {
        name: 'mapCoordinatesExpandable',
        components: {
            InputCharCounter,
        },
        props: {
            mapCoordinates:{type: Array,required: false},
            disabled: { type: Boolean, default: false, required: false },
            isViewMode:{type: Boolean, required: false, default: false }
        },
        methods: {
            add(){
               this.mapCoordinates.push({
                   lat: null,
                   lng: null,
                   height: null
               })
            },
            deleteRow(index) {
                this.mapCoordinates.splice(index, 1)
            }
        },
    }
</script>
