var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h6", [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.selection")))
      ]),
      _vm.selections && _vm.selections.length > 0
        ? _c("div", { staticClass: "mt-4 " }, [
            _c("label", { staticClass: "text-sm w-1/2" }, [
              _vm._v(
                _vm._s(_vm.$t("resorts.touristic-objects.form.selectionType")) +
                  " "
              )
            ]),
            _c("label", { staticClass: "text-sm w-1/2 pl-5" }, [
              _vm._v(
                _vm._s(_vm.$t("resorts.touristic-objects.form.weightInput")) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _vm._l(this.selections, function(option, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            { staticClass: "flex items-center mt-1" },
            [
              _c("InputCharCounter", {
                attrs: {
                  componentClases: "w-1/2 h-16",
                  ModelValue: option.selection,
                  maxLength: 100,
                  disabled: _vm.disabled,
                  isCounterInvisible: _vm.isCounterInvisible
                },
                on: {
                  input: function(value) {
                    option.selection = value
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "w-1/2 flex flex-row items-center  " },
                [
                  _c("vs-input", {
                    staticClass: "pb-5 ml-5 w-2/3",
                    attrs: {
                      type: "number",
                      value: option.weight || 0,
                      disabled: _vm.disabled
                    },
                    model: {
                      value: option.weight,
                      callback: function($$v) {
                        _vm.$set(option, "weight", $$v)
                      },
                      expression: "option.weight"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "ml-3" },
                    [
                      !_vm.disabled
                        ? _c(
                            "vs-button",
                            {
                              staticClass:
                                "px-2 pt-1 pb-0 mb-5 mr-2 rounded-lg ",
                              attrs: {
                                type: "border",
                                color: "rgb(255, 0, 0)"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteRow(index)
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "TrashIcon",
                                  svgClasses: " align-middle w-6"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      }),
      _c(
        "div",
        { staticClass: "w-full mt-5" },
        [
          !_vm.disabled
            ? _c(
                "vs-button",
                {
                  staticClass: "rounded-lg xs:w-full sm:w-auto text-primary",
                  attrs: { type: "border" },
                  on: { click: _vm.add }
                },
                [
                  _vm._v(
                    _vm._s(
                      this.$t("resorts.touristic-objects.form.addSelection")
                    ) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }