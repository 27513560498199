<template>
    <div class="mt-4  bg-white p-8">
        <div class=" rounded-lg advice-container w-full  md:w-3/3 lg:w-2/2 flex justify-center items-center mb-3 ">
            <div class=" px-3 text-xs md:text-lg sm:w-1/1 md:w-3/3 lg:w-2/2  flex justify-center items-center">
                <feather-icon icon="InfoIcon" svgClasses="text-primary  w-5" class=" align-middle mt-0 mr-3" />
                <p class="mt-4 text-primary" >   {{ $t("resorts.touristic-objects.advice") }}</p>
            </div>
        </div>

        <h4 >{{ this.$t('resorts.touristic-objects.form.mainTitle') }}</h4>
         <p class = "text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.mainSubtitle') }}</p>

         <div class="flex flex-row items-center ml-0 gap-4 ">
            <vs-input
                type="text"
                class="w-1/5 "
                v-model="touristicObject.resort_reference"
                :label="this.$t('resorts.touristic-objects.form.resortReference')"
                :disabled="true"
            />
        </div>

         <h6 class="mt-8">  {{ this.$t('resorts.touristic-objects.view.publication') }} </h6>
        <vs-checkbox
            class="ml-0 mb-4 mt-4 text-sm text-grey mt-5"
            v-model="touristicObject.published"
            :disabled="true"
            >{{ this.$t('resorts.touristic-objects.view.published') }}
        </vs-checkbox>

        <div class="flex flex-row items-center ml-0 gap-4 ">
            <vs-input
                type="text"
                class="w-1/5 mb-2 mb-4"
                v-model="touristicObject.timeofyear"
                :label="this.$t('resorts.touristic-objects.view.timeofyearLabel')"
                :disabled="true"
            />
        </div>
        <div v-if="touristicObject.category == 'event'" class="flex flex-row items-center ml-0 mt-4 gap-4">
            <label class="text-sm">Start date</label>
            <b-form-datepicker  class="w-80 mb-1 rounded-lg"
                v-model="touristicObject.start_date"
                :disabled="true"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
        </div>
        <div v-if="touristicObject.category == 'event'" class="flex flex-row items-center ml-0 mt-4 gap-4 ">
            <label class="text-sm">End date</label>
            <b-form-datepicker  class="w-80 mb-1 rounded-lg"
                v-model="touristicObject.end_date"
                :disabled="true"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
        </div>

        <selectionExpandable v-if="touristicObject.selections.length > 0"
           :selections="touristicObject.selections"
           :disabled="true"
           :isCounterInvisible=true
           :isViewMode=true
        ></selectionExpandable>



        <h6 class="mt-4">{{ this.$t('resorts.touristic-objects.view.providerTitle') }}</h6>
        <div class="mb-4 mt-4">
           <vs-input
               class="w-1/5 mb-2"
               type="text"
               v-model="touristicObjectProvider.name"
               :disabled="true"
               :label="this.$t('resorts.touristic-objects.view.providerLabel')"
           />
        </div>

        <h6 class="mt-8" > {{ this.$t('resorts.touristic-objects.view.contentLanguage') }}</h6>
        <div class="mb-10 mt-4">
            <vs-input
                class="w-1/5 mb-2"
                type="text"
                v-model="defaultLanguage.name"
                :disabled="true"
                :label="this.$t('resorts.touristic-objects.view.defaultLanguageLabel')"

            />

            <div class="w-auto mb-2  h-10 clear-both">
                <label class="text-sm"> {{ $t("resorts.touristic-objects.view.languagesLabel") }}</label>

                <div>
                    <vs-chip color ="primary" class=" rounded-md chip mb-8" v-for="(tag, index) in selectedLanguages" :key="index" >{{ tag.name }}</vs-chip>
                </div>
            </div>

        </div>

        <h6  v-if="touristicObjectZone != null || this.touristicObject.category =='service'" class="mb-5 clear-both"> {{ this.$t('resorts.touristic-objects.view.segmentation') }}</h6>
        <div class="flex flex-row items-center ml-0 gap-4">
            <vs-input  v-if="touristicObject.category == 'service'"
                class="w-1/5 mb-2"
                type="text"
                v-model="touristicObjectType.name"
                :disabled="true"
                :label="this.$t('resorts.touristic-objects.view.typeLabel')"

            />
            <vs-input
                v-if="touristicObjectZone != null"
                class="w-1/5 mb-2"
                type="text"
                v-model="touristicObjectZone.name"
                :disabled="true"
                :label="this.$t('resorts.touristic-objects.view.zoneLabel')"

            />
        </div>


        <div v-if="selectedLanguages" class="flex flex-row flex-wrap mb-10 ">
            <TouristicObjectFormCard
                v-for="(language, index) in languages"
                :key="index"
                :language="language"
                :selectedLanguages="languages"
                :index="index"
                :defaultLanguage="defaultLanguage"
                :disabled="true"
                :editMode="false"
                />

            <div class="mt-4 w-full">

                <div class="flex flex-row gap-2">
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.min_height"
                              v-model="touristicObject.min_height"
                              :label="this.$t('resorts.touristic-objects.form.min_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`"
                              :disabled="true"
                    />
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.max_height"
                              v-model="touristicObject.max_height"
                              :label="this.$t('resorts.touristic-objects.form.max_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`"
                              :disabled="true"
                    />
                </div>

                <div class="flex flex-row gap-2">
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.start_height"
                              v-model="touristicObject.start_height"
                              :label="this.$t('resorts.touristic-objects.form.start_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                              :disabled="true"
                    />
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.end_height"
                              v-model="touristicObject.end_height"
                              :label="this.$t('resorts.touristic-objects.form.end_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                              :disabled="true"
                    />
                </div>

                <div class="flex flex-row gap-2">
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.total_ascent"
                              v-model="touristicObject.total_ascent"
                              :label="this.$t('resorts.touristic-objects.form.total_ascent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                              :disabled="true"
                    />
                    <vs-input class="border-grey-light pb-2"
                              type="number"
                              :value="touristicObject.total_descent"
                              v-model="touristicObject.total_descent"
                              :label="this.$t('resorts.touristic-objects.form.total_descent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                              :disabled="true"
                    />
                </div>

                <vs-input class="border-grey-light pb-2"
                          type="number"
                          :value="touristicObject.length"
                          v-model="touristicObject.length"
                          :label="this.$t('resorts.touristic-objects.form.length') + ` (${this.$options.filters.unit_system_converter('distance', this.referenceSystem)})`"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          type="number"
                          :value="touristicObject.time"
                          v-model="touristicObject.time"
                          :label="this.$t('resorts.touristic-objects.form.time')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          type="number"
                          :value="touristicObject.min_age"
                          v-model="touristicObject.min_age"
                          :label="this.$t('resorts.touristic-objects.form.min_age')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          type="number"
                          :value="touristicObject.tickets"
                          v-model="touristicObject.tickets"
                          :label="this.$t('resorts.touristic-objects.form.tickets')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          type="text"
                          :value="touristicObject.page_reference"
                          :label="this.$t('resorts.touristic-objects.form.page')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          :value="touristicObject.subtype ? touristicObject.subtype.name : ''"
                          :label="this.$t('resorts.touristic-objects.form.subtype')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          :value="touristicObject.ride_thrill ? touristicObject.ride_thrill.key : ''"
                          :label="this.$t('resorts.touristic-objects.form.ride_thrill')"
                          :disabled="true"
                />

                <vs-input class="border-grey-light pb-2"
                          :value="touristicObject.ride_audience ? touristicObject.ride_audience.key : ''"
                          :label="this.$t('resorts.touristic-objects.form.ride_audience')"
                          :disabled="true"
                />

                <vs-textarea class="border-grey-light pb-2 w-full"
                          :value="requirementsSelected"
                          v-model="requirementsSelected"
                          :label="this.$t('resorts.touristic-objects.form.requirements')"
                          :disabled="true"
                />
                
                <vs-input class="border-grey-light pb-2"
                          :value="touristicObject.facility_difficulty ? touristicObject.facility_difficulty.name : ''"
                          :label="this.$t('facilities.tableheader.difficulty')"
                          :disabled="true"
                />
            </div>
        </div>


        <h4 >{{ this.$t('resorts.touristic-objects.view.addImage') }}</h4>
        <p class = "text-sm text-dark">{{ this.$t('resorts.touristic-objects.view.addImageSub') }}</p>

        <ImagesForm
            :images="images"
            width="240"
            height="170"
            :editMode="false"
            @maximize="modalPhoto"

        ></ImagesForm>
        <vs-popup  class="modalMap" v-model="isModalOpen" :active.sync="isModalOpen" title="">
            <img :src="maximizedImage.url" :style="{maxWidth:  '100%', maxHeight: '100%' }">
        </vs-popup>

        <h4 class="mt-5">{{ this.$t('resorts.touristic-objects.view.locateMap') }}</h4>
        <p class = "text-sm text-dark mb-6" >{{ this.$t('resorts.touristic-objects.view.locateMapSub') }}</p>
            <MapCoordinates
                :defaultCoordinates=defaultCoordinates
                :componentSize = mapSize
                :disabled="true"
                :zoom=18
            ></MapCoordinates>

        <mapCoordinatesExpandable
            :map-coordinates="mapCoordinates"
            :disabled="true"
        />

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button
                type="border"
               class="rounded-lg xs:w-full sm:w-auto text-primary"
              @click="cancel" >
              {{ this.$t('resorts.touristic-objects.form.backButton') }}
            </vs-button>
        </div>
    </div>

</template>

<script>
import TouristicObjectServices from '@/modules/Resorts/Services/TouristicObjectServices'
import TouristicObjectFormCard from '@/modules/Resorts/Pages/touristicObjects/forms/TouristicObjectFormCard';
import ImagesForm from '@/modules/Resorts/Pages/touristicObjects/forms/ImagesForm'
import MapCoordinates from '@/modules/Shared/Components/mapcoordinates/MapCoordinates.vue';
import loader from "@/modules/Shared/Mixins/loader";
import selectionExpandable from '@/modules/Resorts/Pages/touristicObjects/components/selectionExpandable'
import mapCoordinatesExpandable from "@/modules/Resorts/Pages/touristicObjects/components/mapCoordinatesExpandable.vue";
import {mapGetters} from "vuex";
import PagesService from "@/modules/Resorts/Services/PagesService";


export default {
    name: 'view-touristicObject',
    mixins: [loader],
    components: {
        mapCoordinatesExpandable,
        TouristicObjectFormCard,
        ImagesForm,
        MapCoordinates,
        selectionExpandable
    },
    data() {
        return {
            referenceSystem: 'metric',
            touristicObject:{},
            defaultLanguage:{},
            langs:[],
            selectedLanguages: [],
            touristicObjectType:{},
            touristicObjectZone :{},
            touristicObjectProvider :{},
            languages: [],
            images:[],
            maximizedImage:{},
            isModalOpen: false,
            defaultCoordinates : {lat:0,lon:0},
            mapSize : {width:"w-2/3 md:w-3/3 lg:w-2/2"},
            mapCoordinates: [],
            subtypeId: null,
            rideThrillId: null,
            rideAudienceId: null,
            requirementsSelected: '',
        }
    },
    computed: {
        ...mapGetters('resorts', ['resorts']),
    },
    async created() {
        await this.load(async () => {
            this.referenceSystem = this.resorts.find(resort => resort.uuid === this.$route.params.uuid).reference_system
            const touristicObject = await TouristicObjectServices.getTouristicObject(this.$route.params.touristicObjectUuid)
            this.touristicObject = touristicObject.data.object
            this.touristicObject.page_reference = ''
            if (this.touristicObject.page_id) {
                const page = (await PagesService.getPages(this.$route.params.uuid)).data.find(page => page.id === this.touristicObject.page_id);
                this.touristicObject.page_reference = page ? page.reference : '';
            }
            const languages= await TouristicObjectServices.getLangs()


            this.langs = languages.data.object
            this.touristicObjectType = this.touristicObject.type
            this.touristicObjectZone = this.touristicObject.zone
            this.touristicObjectProvider = this.touristicObject.touristic_object_data_provider
            this.images = this.touristicObject.image_list


            this.handleToForm()
        })
    },
    methods: {
        parseMapCoordinatesAndHeightToForm(mapCoordinates, heights) {
            if (!mapCoordinates || !heights) {
                this.mapCoordinates = [
                    { lat: null, lng: null, height: null }
                ]
                return
            }

            for (let i = 0; i < mapCoordinates.length; i++) {
                this.mapCoordinates.push({
                    lat: mapCoordinates[i][0],
                    lng: mapCoordinates[i][1],
                    height: heights[i]
                })
            }
        },
        handleToForm(){
            this.langs.map((item) =>{
                if(item.id == this.touristicObject.default_language.id){
                    this.defaultLanguage = item
                    this.selectedLanguages.push(item)
                }
            })
            this.touristicObject.langs_list.map((item)=>{
                if (item.idioma_id != this.touristicObject.default_language.id){
                    this.langs.forEach(lang => {
                        if(item.idioma_id == lang.id){
                            this.selectedLanguages.push(lang)
                        }
                    });
                }
            })

            this.touristicObject.langs_list.forEach(translation => {
                let language =  this.langs.filter(idiom => idiom.id == translation.idioma_id)[0];
                language['translation']= translation
                language['touristicObject']= this.touristicObject
                this.languages.push(language)
            });

            this.defaultCoordinates = {lat:this.touristicObject.coordinates.lat,lon:this.touristicObject.coordinates.lon}

            this.subtypeId = this.touristicObject.subtype ? this.touristicObject.subtype.id : null
            this.rideThrillId = this.touristicObject.ride_thrill ? this.touristicObject.ride_thrill.id : null
            this.rideAudienceId = this.touristicObject.ride_audience ? this.touristicObject.ride_audience.id : null
            this.requirementsSelected = this.touristicObject.requirements ? this.touristicObject.requirements.map((requirement) => requirement.key) : []

            this.parseMapCoordinatesAndHeightToForm(this.touristicObject.map_coordinates, this.touristicObject.heights)

            if (this.referenceSystem === 'imperial') {
                this.touristicObject.min_height = this.$options.filters.value_system_converter(this.touristicObject.min_height, this.referenceSystem, 'depth')
                this.touristicObject.max_height = this.$options.filters.value_system_converter(this.touristicObject.max_height, this.referenceSystem, 'depth')
                this.touristicObject.start_height = this.$options.filters.value_system_converter(this.touristicObject.start_height, this.referenceSystem, 'height')
                this.touristicObject.end_height = this.$options.filters.value_system_converter(this.touristicObject.end_height, this.referenceSystem, 'height')
                this.touristicObject.total_ascent = this.$options.filters.value_system_converter(this.touristicObject.total_ascent, this.referenceSystem, 'height')
                this.touristicObject.total_descent = this.$options.filters.value_system_converter(this.touristicObject.total_descent, this.referenceSystem, 'height')
                this.touristicObject.length = this.$options.filters.value_system_converter(this.touristicObject.length, this.referenceSystem, 'distance')
            }
        },
        modalPhoto(image){
            this.maximizedImage = image
            this.isModalOpen = true
        },
        cancel(){
            this.goBackToList()
        },
        goBackToList(){
            if (this.touristicObject.category == 'event')this.$router.push({name:'ToEvents'})
            if (this.touristicObject.category == 'service')this.$router.push({name:'ToServices'})
            if (this.touristicObject.category == 'facility')this.$router.push({name:'ToFacilities'})
        },
    },

}


</script>

<style>
.advice-container{
    background-color: rgba(164, 193, 239, 0.309);
}


</style>
