import axios from '@/axios.js'

export default {
    getPages: async (resortUuid, parameters = {}) => {
        try {
            const response = await axios.editorapi.get(`pages/v3/resorts/${resortUuid}`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    savePage: async (resortUuid, page, content) => {
        try {
            const response = await axios.editorapi.patch(
                `pages/v3/resorts/${resortUuid}/${page}`, 
                content
            )
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getPageLang: async (resortUuid, page, language) => {
        try {
            const response = await axios.editorapi.get(`pages/v3/resorts/${resortUuid}/${page}/${language}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    savePageLang: async (resortUuid, pageUuid, language, content) => {
        try {
            const response = await axios.editorapi.patch(
                `pages/v3/resorts/${resortUuid}/${pageUuid}/${language}`,
                content
            )
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}