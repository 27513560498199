var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap mb-3" },
        _vm._l(_vm.images, function(image, index) {
          return _c(
            "div",
            { key: index, staticClass: "shadow-md m-3 rounded" },
            [
              _c("img", {
                staticClass: " object-cover  rounded-tl rounded-tr",
                style: { width: _vm.width + "px", height: _vm.height + "px" },
                attrs: { src: image.url }
              }),
              _c(
                "div",
                {
                  staticClass: "image-actions  flex justify-end",
                  style: {
                    width: _vm.width + "px",
                    height: _vm.height / 3.7 + "px"
                  }
                },
                [
                  _c("div", { staticClass: "w-50 " }, [
                    image.main
                      ? _c("p", { staticClass: "float-left text-sm mt-3" }, [
                          _c("b", [_vm._v("Main image")])
                        ])
                      : _c("p", { staticClass: "text-center" })
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "text-primary px-0 py-0 rounded-lg",
                      attrs: { type: "border" }
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mt-0",
                        attrs: {
                          icon: "Maximize2Icon",
                          svgClasses: "text-primary w-6"
                        },
                        on: {
                          click: function($event) {
                            return _vm.maximize(image)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.editMode
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "text-primary  px-0 py-0  rounded-lg",
                          attrs: { type: "border" }
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mt-0",
                            attrs: {
                              icon: "TrashIcon",
                              svgClasses: "text-primary  w-6"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _c("vs-input", {
        attrs: {
          type: "file",
          name: _vm.name,
          hidden: "",
          id: _vm.id,
          danger: _vm.validateError,
          "danger-text": _vm.validationMessage,
          multiple: ""
        },
        on: { change: _vm.onFileChange }
      }),
      _vm.editMode
        ? _c("div", { staticClass: "flex " }, [
            _c(
              "div",
              { staticClass: "mt-3 flex" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass:
                      "button-select-image  inline-block rounded-lg mr-2 p-3",
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.handlerChooseFiles()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("resorts.touristic-objects.form.selectImage")
                      )
                    )
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass:
                      "button-select-image  inline-block rounded-lg mr-2 p-3",
                    attrs: { type: "border" },
                    on: {
                      click: function($event) {
                        return _vm.deleteAllImages()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("resorts.touristic-objects.form.deleteImages")
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("p", { staticClass: "text-xs text-grey mt-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("resorts.touristic-objects.form.imageSpecification"))
        )
      ]),
      _c(
        "vs-popup",
        {
          staticClass: "modalMap",
          attrs: { active: _vm.isModalOpen, title: "" },
          on: {
            "update:active": function($event) {
              _vm.isModalOpen = $event
            }
          },
          model: {
            value: _vm.isModalOpen,
            callback: function($$v) {
              _vm.isModalOpen = $$v
            },
            expression: "isModalOpen"
          }
        },
        [
          _c("img", {
            style: { maxWidth: "100%", maxHeight: "100%" },
            attrs: { src: _vm.maximizedImage.url }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }