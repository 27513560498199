<template>
  <div class="w-full lg:w-5/12  relative mt-6 lg:mr-6 p-6 border border-grey rounded">
      <div class="flex flex-row">
        <div class="w-1/3  mb-0" >
          <h5>{{ language.name }}</h5>
        </div>
        <div class="w-2/3  " v-if="editMode == true" >          
          <vs-button v-if="language.id != defaultLanguage.id"   type="border" class="text-primary float-right w-8 px-0 py-0  rounded-lg"  @click="handleDelete(language)">
            <feather-icon icon="TrashIcon" svgClasses="text-primary  w-5" class="align-middle mt-0" />
          </vs-button>
          
        </div>  
      </div>
      <div class="w-2/3 mb-2  " v-if="editMode == true" >  
        <p class=" text-sm text-grey " v-if="language.id === defaultLanguage.id"> {{ this.$t('resorts.touristic-objects.form.mainLanguage') }}</p>
      </div>
      <div class="w-2/3 mb-5 " v-if="editMode == false" >  
        <p class=" absolute text-sm text-grey " v-if="language.id === defaultLanguage.id"> {{ this.$t('resorts.touristic-objects.form.mainLanguage') }}</p>
      </div>

      <!-- Name -->
      <h6 class="mt-4" >{{ this.$t('resorts.touristic-objects.generalContent') }}</h6>

      <label  class="text-sm mt-1 ">{{ this.$t('resorts.touristic-objects.form.name') }}</label>
          <vs-input  
            class="w-full" 
            type="text"
            :value="translation.name || '' "
            v-model="translation.name"
            :disabled="disabled"
            required
          />
          <p v-if="!disabled"  class="text-right  text-xs">
             <span
               :class="{
                 'text-danger': nameLength > maxInputLength50,
                 'text-success': nameLength <= maxInputLength50,
               }"
             >{{ nameLength }}</span>
             /{{ maxInputLength50 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
          </p>

      <label  class="text-sm mt-1 ">{{ this.$t('resorts.touristic-objects.form.highlight') }}</label>
      <vs-input
          class="w-full"
          type="text"
          :value="translation.highlight || '' "
          v-model="translation.highlight"
          :disabled="disabled"
          required
      />
      <p v-if="!disabled"  class="text-right  text-xs">
             <span
                 :class="{
                 'text-danger': nameLength > maxInputLength50,
                 'text-success': nameLength <= maxInputLength50,
               }"
             >{{ highlightLength }}</span>
          /{{ maxInputLength50 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>

      <!-- Address -->
      <label class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.address') }}</label>
      <vs-input class="w-full" 
        type="text"
        :value="touristicObject.address || '' "
        v-model="touristicObject.address"        
        :disabled="disabled"

      />
      <p v-if="!disabled"  class="text-right text-xs">
        <span
          :class="{
            'text-danger': addressLength > maxInputLength50,
            'text-success': addressLength <= maxInputLength50,
          }"
        >{{ addressLength }}</span>
        /{{ maxInputLength50 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>

      <!-- Phones -->      
      <div class="flex flex-row items-center ml-0 justify-between ">
        <div class=" w-1/2 pr-1">
          <label class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.phone1') }}</label>
          <vs-input 
            class="w-full"
            type="text"
            :value="translation.phone || '' "
            v-model="translation.phone"
            required
            :disabled="disabled"
          />      
          <p v-if="!disabled"  class="text-right text-xs  ">
            <span
              :class="{
                'text-danger': phoneLength > maxInputLength20,
                'text-success': phoneLength <= maxInputLength20,
              }"
            >{{ phoneLength }}</span>
            /{{ maxInputLength20 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
          </p>

        </div>

        <div class=" w-1/2 pl-1">
          <label  class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.phone2') }}</label>
          <vs-input 
           class="w-full"
            type="text"
            :value="translation.other_phone || '' "
            v-model="translation.other_phone"
            required
            :disabled="disabled"
          />
          <p v-if="!disabled"  class="text-right text-xs clear-both">
            <span
              :class="{
                'text-danger': otherPhoneLength > maxInputLength20,
                'text-success': otherPhoneLength <= maxInputLength20,
              }"
            >{{ otherPhoneLength }}</span>
            /{{ maxInputLength20 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
          </p>
        </div>
      </div>

      <!-- Emails -->
      <div class=" view flex flex-row items-center ml-0 justify-between">
        <div  class="w-1/2 pr-1 ">
          <label  class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.email1') }}</label>
          <vs-input 
            class="w-full"
            type="text"
            :value="translation.email || '' "
            v-model="translation.email"
            required
            :disabled="disabled"
          />    
          <p v-if="!disabled"  class="text-right text-xs ">
            <span
              :class="{
                'text-danger': EmailLength > maxInputLength100,
                'text-success': EmailLength <= maxInputLength100,
              }"
            >{{ EmailLength }}</span>
            /{{ maxInputLength100 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
         </p>
        </div>
        
        <div  class="w-1/2 pl-1 ">
          <label  class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.email2') }}</label>
          <vs-input 
          type="text"
          class="w-full"
          :value="translation.other_email || '' "
          v-model="translation.other_email"
          required
          :disabled="disabled"
          />
          <p v-if="!disabled"  class="text-right text-xs clear-both">
            <span
              :class="{
                'text-danger': otherEmailLength > maxInputLength100,
                'text-success': otherEmailLength <= maxInputLength100,
              }"
            >{{ otherEmailLength }}</span>
            /{{ maxInputLength100 }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
         </p>

        </div>
      </div>

      <!-- Website -->
      <label class="text-sm mt-1">{{ this.$t('resorts.touristic-objects.form.website') }}</label>
      <vs-input class="w-full"
          type="text"
          :value="translation.web_link || '' "
          v-model="translation.web_link"
          required
          :disabled="disabled"
      />     
      <p v-if="!disabled"  class="text-right text-xs">
       <span
         :class="{
           'text-danger': webLength > maxInputLength65k,
           'text-success': webLength <= maxInputLength65k,
         }"
       >{{ webLength }}</span>
       /{{ maxInputLength65k }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>

      <!-- Reservation -->
      <label class="text-sm">{{ this.$t('resorts.touristic-objects.form.reservationLink') }}</label>      
      <vs-input class="w-full"
          type="text"
          :value="translation.reservation || '' "
          v-model="translation.reservation_link"
          required
          :disabled="disabled"
      />     
      <p v-if="!disabled"  class="text-right text-xs">
        <span
          :class="{
            'text-danger': reservationLength > maxInputLength65k,
            'text-success': reservationLength <= maxInputLength65k,
          }"
        >{{ reservationLength }}</span>
        /{{ maxInputLength65k }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>

      <!-- Fares -->
      <h6 class="mt-4" >{{ this.$t('resorts.touristic-objects.form.ratesTitle') }}</h6>
      <textarea class="w-full h-24 p-1 rounded resize-none border-grey-light"    
          v-model="translation.rates"
          required
          :disabled="disabled"                    
      ></textarea>
      <p v-if="!disabled"  class="text-right text-xs">
        <span
          :class="{
            'text-danger': ratesLength > maxInputLength65k,
            'text-success': ratesLength <= maxInputLength65k,
          }"
        >{{ ratesLength }}</span>
        /{{ maxInputLength65k }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>

      <!-- Description -->
      <h6 class="mt-4" >{{ this.$t('resorts.touristic-objects.form.descriptionTitle') }}</h6>
      <textarea class="w-full h-24 p-1 rounded resize-none border-grey-light"  
          v-model="translation.description"       
          :disabled="disabled"   
      ></textarea>
      <p v-if="!disabled"  class="text-right text-xs">
        <span
          :class="{
            'text-danger': descriptionLength > maxInputLength65k,
            'text-success': descriptionLength <= maxInputLength65k,
          }"
        >{{ descriptionLength }}</span>
        /{{ maxInputLength65k }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>      

      <!-- schedule -->
      <h6 class="mt-4">{{ this.$t('resorts.touristic-objects.form.scheduleTitle') }}</h6>
      <textarea class="w-full w-full h-24 p-1 rounded resize-none border-grey-light"                    
          v-model="translation.schedule"
          required
          :disabled="disabled"
      ></textarea> 
      <p v-if="!disabled"  class="text-right text-xs">
        <span
          :class="{
            'text-danger': scheduleLength > maxInputLength65k,
            'text-success': scheduleLength <= maxInputLength65k,
          }"
        >{{ scheduleLength }}</span>
        /{{ maxInputLength65k }} {{ this.$t('resorts.touristic-objects.form.maxChars') }}
      </p>    


      <!-- Tags -->       
      <h6 class="mt-4">Tags</h6> 
        <!-- Editables -->
      <div  v-if="!disabled"> 
        <div class="mb-5 clear-both">
          <vs-input  v-model="serviceValue" :label="this.$t('resorts.touristic-objects.form.services')" @keyup.enter="addServiceChip()"  @blur="addServiceChip()"/>        
          <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="(tag, index) in translation.services_tags" :key="tag" closable @click="removeServiceTag(index)">{{ tag }}</vs-chip>
        </div>
  
        <div class="mb-5 clear-both">
          <vs-input  v-model="equipmentValue" :label="this.$t('resorts.touristic-objects.form.equipments')"  @keyup.enter="addServiceChip()" @blur="addEquipmentChip()"/>        
          <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="(tag, index) in translation.equipments_tags" :key="tag" closable @click="removeEquipmentTag(index)">{{ tag }}</vs-chip>
        </div>
        <div class="mb-5 clear-both">
          <vs-input   v-model="comfortValue" :label="this.$t('resorts.touristic-objects.form.comfort')" @keyup.enter="addServiceChip()"  @blur="addComfortChip()"/>        
          <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="(tag, index) in translation.comfort_tags" :key="tag" closable @click="removeComfortTag(index)">{{ tag }}</vs-chip>
        </div>
        <div class="mb-5 clear-both">
          <vs-input v-model="accessibilityValue" :label="this.$t('resorts.touristic-objects.form.accessibility')" @keyup.enter="addServiceChip()" @blur="addAccessibilityChip()"/>        
          <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="(tag, index) in translation.accessibility_tags" :key="tag" closable @click="removeAccessibilityTag(index)">{{ tag }}</vs-chip>
        </div>
        <div class="mb-5 clear-both">
          <vs-input  v-model="paymentsValue" :label="this.$t('resorts.touristic-objects.form.payments')"  @keyup.enter="addServiceChip()" @blur="addPaymentChip()"/>        
          <vs-chip  color ="primary" class=" mt-2 rounded-md chip" v-for="(tag, index) in translation.payments_mode_tags" :key="tag" closable  @click="removePaymentTag(index)">{{ tag }}</vs-chip>
        </div>
      </div>

      <!--NO Editables -->
      <div  v-if="disabled"> 
        <div v-if="translation.services_tags.length > 0" class="clear-both ">
          <label class="text-sm mt-4">Services</label>
          <div >
            <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="tag in translation.services_tags" :key="tag" >{{ tag }}</vs-chip>
          </div>
        </div>
        <div v-if="translation.equipments_tags.length > 0" class="clear-both ">
          <label class="text-sm mt-4">Equipments</label>
          <div>
            <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="tag in translation.equipments_tags" :key="tag" >{{ tag }}</vs-chip>
          </div>
        </div>
        <div class="clear-both" v-if="translation.comfort_tags.length > 0" >
          <label class="text-sm mt-4">Comfort</label>
          <div>
            <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="tag in translation.comfort_tags" :key="tag">{{ tag }}</vs-chip>
          </div>
        </div>
        <div class="clear-both" v-if="translation.accessibility_tags.length > 0">
          <label class="text-sm mt-4">Accessibility</label>
          <div>
            <vs-chip color ="primary" class=" mt-2 rounded-md chip" v-for="tag in translation.accessibility_tags" :key="tag">{{ tag }}</vs-chip>
          </div>
        </div>
        <div class="clear-both" v-if="translation.payments_mode_tags.length > 0">
          <label class="text-sm mt-4">Payments</label>
          <div>
            <vs-chip  color ="primary" class=" mt-2 rounded-md chip" v-for="tag in translation.payments_mode_tags" :key="tag">{{ tag }}</vs-chip>
          </div>
        </div>

      </div>
  </div>
</template>

<script>

export default {
    name: 'touristicObject-form-card',    
    props: {
      language: { type: Object,  required: false, default: null },
      defaultLanguage:{ type: Object,  required: false, default: null },
      selectedLanguages:{type: Array,required:false, default:null},
      deletedTranslations:{type: Array,required:false,default:null},
      'disabled': {
          'type': Boolean,
          'default': false
        },
      'editMode': {
          'type': Boolean,
          'default': false
        },
      
    },    
    data() {
        return {
          touristicObject:{},
          translation:{},
          serviceValue:'',
          equipmentValue:'',
          comfortValue:'',
          accessibilityValue:'',          
          paymentsValue:'', 
          maxInputLength50: 50,           
          maxInputLength100: 100,           
          maxInputLength20: 20,           
          maxInputLength512: 512,           
          maxInputLength65k: 65535,           
      }
    },  
    computed:{
      otherEmailLength() {return this.translation.other_email ? this.translation.other_email.length : 0},
      EmailLength() {return this.translation.email ? this.translation.email.length : 0},
      nameLength() {return  this.translation.name ? this.translation.name.length : 0},
      highlightLength() {return  this.translation.highlight ? this.translation.highlight.length : 0},
      addressLength() {return this.touristicObject.address ? this.touristicObject.address.length : 0},
      phoneLength() {return  this.translation.phone ? this.translation.phone.length : 0},
      otherPhoneLength() {return this.translation.other_phone ? this.translation.other_phone.length : 0},
      webLength() {return this.translation.web_link ? this.translation.web_link.length : 0},
      reservationLength() {return this.translation.reservation_link ? this.translation.reservation_link.length : 0},
      ratesLength() {return this.translation.rates ? this.translation.rates.length : 0},
      descriptionLength() {return this.translation.description ? this.translation.description.length : 0},
      scheduleLength() {return this.translation.schedule ? this.translation.schedule.length : 0},      
    }, 
    async created() {       
      this.touristicObject = this.language.touristicObject      
      this.translation = this.language.translation 
      this.handleForm()
    },     
    methods: {
      handleForm(){
        if(this.translation.services_tags == null) {this.translation.services_tags = []}
        if(this.translation.equipments_tags == null) {this.translation.equipments_tags = []}
        if(this.translation.comfort_tags == null) {this.translation.comfort_tags = []}
        if(this.translation.accessibility_tags == null) {this.translation.accessibility_tags = []}
        if(this.translation.payments_mode_tags == null) {this.translation.payments_mode_tags = []}           
      },
      handleDelete(language) {   
        this.deletedTranslations.push(language.translation)
        let deleteIndex = this.selectedLanguages.findIndex((l) => l.id == language.id)
        this.selectedLanguages.splice(deleteIndex,1)
      },
      addServiceChip() {
        if (this.serviceValue) {
          this.translation.services_tags.push(this.serviceValue);
          this.serviceValue = '';
        }
      },
      addEquipmentChip() {
        if (this.equipmentValue) {
          this.translation.equipments_tags.push(this.equipmentValue);
          this.equipmentValue = '';
        }
      },
      addComfortChip() {
        if (this.comfortValue) {
          this.translation.comfort_tags.push(this.comfortValue);
          this.comfortValue = '';
        }
      },
      addAccessibilityChip() {
        if (this.accessibilityValue) {
          this.translation.accessibility_tags.push(this.accessibilityValue);
          this.accessibilityValue = '';
        }
      },
      addPaymentChip() {
        if (this.paymentsValue) {
          this.translation.payments_mode_tags.push(this.paymentsValue);
          this.paymentsValue = '';
        }
      },
      
      removeServiceTag(index) { this.translation.services_tags.splice(index, 1);},
      removeEquipmentTag(index) { this.translation.equipments_tags.splice(index, 1);},
      removeComfortTag(index) { this.translation.comfort_tags.splice(index, 1);},
      removeAccessibilityTag(index) { this.translation.accessibility_tags.splice(index, 1);},
      removePaymentTag(index) { this.translation.payments_mode_tags.splice(index, 1);},
    },
    
}
</script>
<style  >
  .chip  > button{
    background: transparent;
    margin-left: 4px;
    
  }
  .chip  > button > i {
    color: white;
    
  }

  @media only screen and (min-width: 1700px)  {
  
    .responsiveInputs {
      width: 50%;

    }
  }
  
</style>