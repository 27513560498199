var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4  bg-white p-8" },
    [
      _c(
        "div",
        {
          staticClass:
            " rounded-lg advice-container w-full  md:w-3/3 lg:w-2/2 flex justify-center items-center mb-3 "
        },
        [
          _c(
            "div",
            {
              staticClass:
                " px-3 text-xs md:text-lg sm:w-1/1 md:w-3/3 lg:w-2/2  flex justify-center items-center"
            },
            [
              _c("feather-icon", {
                staticClass: " align-middle mt-0 mr-3",
                attrs: { icon: "InfoIcon", svgClasses: "text-primary  w-5" }
              }),
              _c("p", { staticClass: "mt-4 text-primary" }, [
                _vm._v(" " + _vm._s(_vm.$t("resorts.touristic-objects.advice")))
              ])
            ],
            1
          )
        ]
      ),
      _c("h4", [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.mainTitle")))
      ]),
      _c("p", { staticClass: "text-sm text-dark" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.mainSubtitle")))
      ]),
      _c(
        "div",
        { staticClass: "flex flex-row items-center ml-0 gap-4 " },
        [
          _c("vs-input", {
            staticClass: "w-1/5 ",
            attrs: {
              type: "text",
              label: this.$t("resorts.touristic-objects.form.resortReference"),
              disabled: true
            },
            model: {
              value: _vm.touristicObject.resort_reference,
              callback: function($$v) {
                _vm.$set(_vm.touristicObject, "resort_reference", $$v)
              },
              expression: "touristicObject.resort_reference"
            }
          })
        ],
        1
      ),
      _c("h6", { staticClass: "mt-8" }, [
        _vm._v(
          " " +
            _vm._s(this.$t("resorts.touristic-objects.view.publication")) +
            " "
        )
      ]),
      _c(
        "vs-checkbox",
        {
          staticClass: "ml-0 mb-4 mt-4 text-sm text-grey mt-5",
          attrs: { disabled: true },
          model: {
            value: _vm.touristicObject.published,
            callback: function($$v) {
              _vm.$set(_vm.touristicObject, "published", $$v)
            },
            expression: "touristicObject.published"
          }
        },
        [
          _vm._v(
            _vm._s(this.$t("resorts.touristic-objects.view.published")) + " "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-row items-center ml-0 gap-4 " },
        [
          _c("vs-input", {
            staticClass: "w-1/5 mb-2 mb-4",
            attrs: {
              type: "text",
              label: this.$t("resorts.touristic-objects.view.timeofyearLabel"),
              disabled: true
            },
            model: {
              value: _vm.touristicObject.timeofyear,
              callback: function($$v) {
                _vm.$set(_vm.touristicObject, "timeofyear", $$v)
              },
              expression: "touristicObject.timeofyear"
            }
          })
        ],
        1
      ),
      _vm.touristicObject.category == "event"
        ? _c(
            "div",
            { staticClass: "flex flex-row items-center ml-0 mt-4 gap-4" },
            [
              _c("label", { staticClass: "text-sm" }, [_vm._v("Start date")]),
              _c("b-form-datepicker", {
                staticClass: "w-80 mb-1 rounded-lg",
                attrs: {
                  disabled: true,
                  "date-format-options": {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  }
                },
                model: {
                  value: _vm.touristicObject.start_date,
                  callback: function($$v) {
                    _vm.$set(_vm.touristicObject, "start_date", $$v)
                  },
                  expression: "touristicObject.start_date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.touristicObject.category == "event"
        ? _c(
            "div",
            { staticClass: "flex flex-row items-center ml-0 mt-4 gap-4 " },
            [
              _c("label", { staticClass: "text-sm" }, [_vm._v("End date")]),
              _c("b-form-datepicker", {
                staticClass: "w-80 mb-1 rounded-lg",
                attrs: {
                  disabled: true,
                  "date-format-options": {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  }
                },
                model: {
                  value: _vm.touristicObject.end_date,
                  callback: function($$v) {
                    _vm.$set(_vm.touristicObject, "end_date", $$v)
                  },
                  expression: "touristicObject.end_date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.touristicObject.selections.length > 0
        ? _c("selectionExpandable", {
            attrs: {
              selections: _vm.touristicObject.selections,
              disabled: true,
              isCounterInvisible: true,
              isViewMode: true
            }
          })
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.view.providerTitle")))
      ]),
      _c(
        "div",
        { staticClass: "mb-4 mt-4" },
        [
          _c("vs-input", {
            staticClass: "w-1/5 mb-2",
            attrs: {
              type: "text",
              disabled: true,
              label: this.$t("resorts.touristic-objects.view.providerLabel")
            },
            model: {
              value: _vm.touristicObjectProvider.name,
              callback: function($$v) {
                _vm.$set(_vm.touristicObjectProvider, "name", $$v)
              },
              expression: "touristicObjectProvider.name"
            }
          })
        ],
        1
      ),
      _c("h6", { staticClass: "mt-8" }, [
        _vm._v(
          " " +
            _vm._s(this.$t("resorts.touristic-objects.view.contentLanguage"))
        )
      ]),
      _c(
        "div",
        { staticClass: "mb-10 mt-4" },
        [
          _c("vs-input", {
            staticClass: "w-1/5 mb-2",
            attrs: {
              type: "text",
              disabled: true,
              label: this.$t(
                "resorts.touristic-objects.view.defaultLanguageLabel"
              )
            },
            model: {
              value: _vm.defaultLanguage.name,
              callback: function($$v) {
                _vm.$set(_vm.defaultLanguage, "name", $$v)
              },
              expression: "defaultLanguage.name"
            }
          }),
          _c("div", { staticClass: "w-auto mb-2  h-10 clear-both" }, [
            _c("label", { staticClass: "text-sm" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("resorts.touristic-objects.view.languagesLabel")
                  )
              )
            ]),
            _c(
              "div",
              _vm._l(_vm.selectedLanguages, function(tag, index) {
                return _c(
                  "vs-chip",
                  {
                    key: index,
                    staticClass: " rounded-md chip mb-8",
                    attrs: { color: "primary" }
                  },
                  [_vm._v(_vm._s(tag.name))]
                )
              }),
              1
            )
          ])
        ],
        1
      ),
      _vm.touristicObjectZone != null ||
      this.touristicObject.category == "service"
        ? _c("h6", { staticClass: "mb-5 clear-both" }, [
            _vm._v(
              " " +
                _vm._s(this.$t("resorts.touristic-objects.view.segmentation"))
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row items-center ml-0 gap-4" },
        [
          _vm.touristicObject.category == "service"
            ? _c("vs-input", {
                staticClass: "w-1/5 mb-2",
                attrs: {
                  type: "text",
                  disabled: true,
                  label: this.$t("resorts.touristic-objects.view.typeLabel")
                },
                model: {
                  value: _vm.touristicObjectType.name,
                  callback: function($$v) {
                    _vm.$set(_vm.touristicObjectType, "name", $$v)
                  },
                  expression: "touristicObjectType.name"
                }
              })
            : _vm._e(),
          _vm.touristicObjectZone != null
            ? _c("vs-input", {
                staticClass: "w-1/5 mb-2",
                attrs: {
                  type: "text",
                  disabled: true,
                  label: this.$t("resorts.touristic-objects.view.zoneLabel")
                },
                model: {
                  value: _vm.touristicObjectZone.name,
                  callback: function($$v) {
                    _vm.$set(_vm.touristicObjectZone, "name", $$v)
                  },
                  expression: "touristicObjectZone.name"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.selectedLanguages
        ? _c(
            "div",
            { staticClass: "flex flex-row flex-wrap mb-10 " },
            [
              _vm._l(_vm.languages, function(language, index) {
                return _c("TouristicObjectFormCard", {
                  key: index,
                  attrs: {
                    language: language,
                    selectedLanguages: _vm.languages,
                    index: index,
                    defaultLanguage: _vm.defaultLanguage,
                    disabled: true,
                    editMode: false
                  }
                })
              }),
              _c(
                "div",
                { staticClass: "mt-4 w-full" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-row gap-2" },
                    [
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.min_height,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.min_height"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "depth",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.min_height,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "min_height", $$v)
                          },
                          expression: "touristicObject.min_height"
                        }
                      }),
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.max_height,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.max_height"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "depth",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.max_height,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "max_height", $$v)
                          },
                          expression: "touristicObject.max_height"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-row gap-2" },
                    [
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.start_height,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.start_height"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "height",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.start_height,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "start_height", $$v)
                          },
                          expression: "touristicObject.start_height"
                        }
                      }),
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.end_height,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.end_height"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "height",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.end_height,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "end_height", $$v)
                          },
                          expression: "touristicObject.end_height"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-row gap-2" },
                    [
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.total_ascent,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.total_ascent"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "height",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.total_ascent,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "total_ascent", $$v)
                          },
                          expression: "touristicObject.total_ascent"
                        }
                      }),
                      _c("vs-input", {
                        staticClass: "border-grey-light pb-2",
                        attrs: {
                          type: "number",
                          value: _vm.touristicObject.total_descent,
                          label:
                            this.$t(
                              "resorts.touristic-objects.form.total_descent"
                            ) +
                            " (" +
                            this.$options.filters.unit_system_converter(
                              "height",
                              this.referenceSystem
                            ) +
                            ")",
                          disabled: true
                        },
                        model: {
                          value: _vm.touristicObject.total_descent,
                          callback: function($$v) {
                            _vm.$set(_vm.touristicObject, "total_descent", $$v)
                          },
                          expression: "touristicObject.total_descent"
                        }
                      })
                    ],
                    1
                  ),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      type: "number",
                      value: _vm.touristicObject.length,
                      label:
                        this.$t("resorts.touristic-objects.form.length") +
                        " (" +
                        this.$options.filters.unit_system_converter(
                          "distance",
                          this.referenceSystem
                        ) +
                        ")",
                      disabled: true
                    },
                    model: {
                      value: _vm.touristicObject.length,
                      callback: function($$v) {
                        _vm.$set(_vm.touristicObject, "length", $$v)
                      },
                      expression: "touristicObject.length"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      type: "number",
                      value: _vm.touristicObject.time,
                      label: this.$t("resorts.touristic-objects.form.time"),
                      disabled: true
                    },
                    model: {
                      value: _vm.touristicObject.time,
                      callback: function($$v) {
                        _vm.$set(_vm.touristicObject, "time", $$v)
                      },
                      expression: "touristicObject.time"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      type: "number",
                      value: _vm.touristicObject.min_age,
                      label: this.$t("resorts.touristic-objects.form.min_age"),
                      disabled: true
                    },
                    model: {
                      value: _vm.touristicObject.min_age,
                      callback: function($$v) {
                        _vm.$set(_vm.touristicObject, "min_age", $$v)
                      },
                      expression: "touristicObject.min_age"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      type: "number",
                      value: _vm.touristicObject.tickets,
                      label: this.$t("resorts.touristic-objects.form.tickets"),
                      disabled: true
                    },
                    model: {
                      value: _vm.touristicObject.tickets,
                      callback: function($$v) {
                        _vm.$set(_vm.touristicObject, "tickets", $$v)
                      },
                      expression: "touristicObject.tickets"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      type: "text",
                      value: _vm.touristicObject.page_reference,
                      label: this.$t("resorts.touristic-objects.form.page"),
                      disabled: true
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      value: _vm.touristicObject.subtype
                        ? _vm.touristicObject.subtype.name
                        : "",
                      label: this.$t("resorts.touristic-objects.form.subtype"),
                      disabled: true
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      value: _vm.touristicObject.ride_thrill
                        ? _vm.touristicObject.ride_thrill.key
                        : "",
                      label: this.$t(
                        "resorts.touristic-objects.form.ride_thrill"
                      ),
                      disabled: true
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      value: _vm.touristicObject.ride_audience
                        ? _vm.touristicObject.ride_audience.key
                        : "",
                      label: this.$t(
                        "resorts.touristic-objects.form.ride_audience"
                      ),
                      disabled: true
                    }
                  }),
                  _c("vs-textarea", {
                    staticClass: "border-grey-light pb-2 w-full",
                    attrs: {
                      value: _vm.requirementsSelected,
                      label: this.$t(
                        "resorts.touristic-objects.form.requirements"
                      ),
                      disabled: true
                    },
                    model: {
                      value: _vm.requirementsSelected,
                      callback: function($$v) {
                        _vm.requirementsSelected = $$v
                      },
                      expression: "requirementsSelected"
                    }
                  }),
                  _c("vs-input", {
                    staticClass: "border-grey-light pb-2",
                    attrs: {
                      value: _vm.touristicObject.facility_difficulty
                        ? _vm.touristicObject.facility_difficulty.name
                        : "",
                      label: this.$t("facilities.tableheader.difficulty"),
                      disabled: true
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _c("h4", [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.view.addImage")))
      ]),
      _c("p", { staticClass: "text-sm text-dark" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.view.addImageSub")))
      ]),
      _c("ImagesForm", {
        attrs: {
          images: _vm.images,
          width: "240",
          height: "170",
          editMode: false
        },
        on: { maximize: _vm.modalPhoto }
      }),
      _c(
        "vs-popup",
        {
          staticClass: "modalMap",
          attrs: { active: _vm.isModalOpen, title: "" },
          on: {
            "update:active": function($event) {
              _vm.isModalOpen = $event
            }
          },
          model: {
            value: _vm.isModalOpen,
            callback: function($$v) {
              _vm.isModalOpen = $$v
            },
            expression: "isModalOpen"
          }
        },
        [
          _c("img", {
            style: { maxWidth: "100%", maxHeight: "100%" },
            attrs: { src: _vm.maximizedImage.url }
          })
        ]
      ),
      _c("h4", { staticClass: "mt-5" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.view.locateMap")))
      ]),
      _c("p", { staticClass: "text-sm text-dark mb-6" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.view.locateMapSub")))
      ]),
      _c("MapCoordinates", {
        attrs: {
          defaultCoordinates: _vm.defaultCoordinates,
          componentSize: _vm.mapSize,
          disabled: true,
          zoom: 18
        }
      }),
      _c("mapCoordinatesExpandable", {
        attrs: { "map-coordinates": _vm.mapCoordinates, disabled: true }
      }),
      _c(
        "div",
        { staticClass: "flex items-center ml-0 gap-4 mt-5" },
        [
          _c(
            "vs-button",
            {
              staticClass: "rounded-lg xs:w-full sm:w-auto text-primary",
              attrs: { type: "border" },
              on: { click: _vm.cancel }
            },
            [
              _vm._v(
                " " +
                  _vm._s(this.$t("resorts.touristic-objects.form.backButton")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }