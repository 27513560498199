var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full lg:w-5/12  relative mt-6 lg:mr-6 p-6 border border-grey rounded"
    },
    [
      _c("div", { staticClass: "flex flex-row" }, [
        _c("div", { staticClass: "w-1/3  mb-0" }, [
          _c("h5", [_vm._v(_vm._s(_vm.language.name))])
        ]),
        _vm.editMode == true
          ? _c(
              "div",
              { staticClass: "w-2/3  " },
              [
                _vm.language.id != _vm.defaultLanguage.id
                  ? _c(
                      "vs-button",
                      {
                        staticClass:
                          "text-primary float-right w-8 px-0 py-0  rounded-lg",
                        attrs: { type: "border" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(_vm.language)
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "align-middle mt-0",
                          attrs: {
                            icon: "TrashIcon",
                            svgClasses: "text-primary  w-5"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.editMode == true
        ? _c("div", { staticClass: "w-2/3 mb-2  " }, [
            _vm.language.id === _vm.defaultLanguage.id
              ? _c("p", { staticClass: " text-sm text-grey " }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.$t("resorts.touristic-objects.form.mainLanguage")
                      )
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.editMode == false
        ? _c("div", { staticClass: "w-2/3 mb-5 " }, [
            _vm.language.id === _vm.defaultLanguage.id
              ? _c("p", { staticClass: " absolute text-sm text-grey " }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.$t("resorts.touristic-objects.form.mainLanguage")
                      )
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.generalContent")))
      ]),
      _c("label", { staticClass: "text-sm mt-1 " }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.name")))
      ]),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          type: "text",
          value: _vm.translation.name || "",
          disabled: _vm.disabled,
          required: ""
        },
        model: {
          value: _vm.translation.name,
          callback: function($$v) {
            _vm.$set(_vm.translation, "name", $$v)
          },
          expression: "translation.name"
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right  text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.nameLength > _vm.maxInputLength50,
                  "text-success": _vm.nameLength <= _vm.maxInputLength50
                }
              },
              [_vm._v(_vm._s(_vm.nameLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength50) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("label", { staticClass: "text-sm mt-1 " }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.highlight")))
      ]),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          type: "text",
          value: _vm.translation.highlight || "",
          disabled: _vm.disabled,
          required: ""
        },
        model: {
          value: _vm.translation.highlight,
          callback: function($$v) {
            _vm.$set(_vm.translation, "highlight", $$v)
          },
          expression: "translation.highlight"
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right  text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.nameLength > _vm.maxInputLength50,
                  "text-success": _vm.nameLength <= _vm.maxInputLength50
                }
              },
              [_vm._v(_vm._s(_vm.highlightLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength50) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("label", { staticClass: "text-sm mt-1" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.address")))
      ]),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          type: "text",
          value: _vm.touristicObject.address || "",
          disabled: _vm.disabled
        },
        model: {
          value: _vm.touristicObject.address,
          callback: function($$v) {
            _vm.$set(_vm.touristicObject, "address", $$v)
          },
          expression: "touristicObject.address"
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.addressLength > _vm.maxInputLength50,
                  "text-success": _vm.addressLength <= _vm.maxInputLength50
                }
              },
              [_vm._v(_vm._s(_vm.addressLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength50) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row items-center ml-0 justify-between " },
        [
          _c(
            "div",
            { staticClass: " w-1/2 pr-1" },
            [
              _c("label", { staticClass: "text-sm mt-1" }, [
                _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.phone1")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  value: _vm.translation.phone || "",
                  required: "",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.translation.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.translation, "phone", $$v)
                  },
                  expression: "translation.phone"
                }
              }),
              !_vm.disabled
                ? _c("p", { staticClass: "text-right text-xs  " }, [
                    _c(
                      "span",
                      {
                        class: {
                          "text-danger": _vm.phoneLength > _vm.maxInputLength20,
                          "text-success":
                            _vm.phoneLength <= _vm.maxInputLength20
                        }
                      },
                      [_vm._v(_vm._s(_vm.phoneLength))]
                    ),
                    _vm._v(
                      " /" +
                        _vm._s(_vm.maxInputLength20) +
                        " " +
                        _vm._s(
                          this.$t("resorts.touristic-objects.form.maxChars")
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: " w-1/2 pl-1" },
            [
              _c("label", { staticClass: "text-sm mt-1" }, [
                _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.phone2")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  value: _vm.translation.other_phone || "",
                  required: "",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.translation.other_phone,
                  callback: function($$v) {
                    _vm.$set(_vm.translation, "other_phone", $$v)
                  },
                  expression: "translation.other_phone"
                }
              }),
              !_vm.disabled
                ? _c("p", { staticClass: "text-right text-xs clear-both" }, [
                    _c(
                      "span",
                      {
                        class: {
                          "text-danger":
                            _vm.otherPhoneLength > _vm.maxInputLength20,
                          "text-success":
                            _vm.otherPhoneLength <= _vm.maxInputLength20
                        }
                      },
                      [_vm._v(_vm._s(_vm.otherPhoneLength))]
                    ),
                    _vm._v(
                      " /" +
                        _vm._s(_vm.maxInputLength20) +
                        " " +
                        _vm._s(
                          this.$t("resorts.touristic-objects.form.maxChars")
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: " view flex flex-row items-center ml-0 justify-between"
        },
        [
          _c(
            "div",
            { staticClass: "w-1/2 pr-1 " },
            [
              _c("label", { staticClass: "text-sm mt-1" }, [
                _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.email1")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  value: _vm.translation.email || "",
                  required: "",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.translation.email,
                  callback: function($$v) {
                    _vm.$set(_vm.translation, "email", $$v)
                  },
                  expression: "translation.email"
                }
              }),
              !_vm.disabled
                ? _c("p", { staticClass: "text-right text-xs " }, [
                    _c(
                      "span",
                      {
                        class: {
                          "text-danger":
                            _vm.EmailLength > _vm.maxInputLength100,
                          "text-success":
                            _vm.EmailLength <= _vm.maxInputLength100
                        }
                      },
                      [_vm._v(_vm._s(_vm.EmailLength))]
                    ),
                    _vm._v(
                      " /" +
                        _vm._s(_vm.maxInputLength100) +
                        " " +
                        _vm._s(
                          this.$t("resorts.touristic-objects.form.maxChars")
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-1/2 pl-1 " },
            [
              _c("label", { staticClass: "text-sm mt-1" }, [
                _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.email2")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  value: _vm.translation.other_email || "",
                  required: "",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.translation.other_email,
                  callback: function($$v) {
                    _vm.$set(_vm.translation, "other_email", $$v)
                  },
                  expression: "translation.other_email"
                }
              }),
              !_vm.disabled
                ? _c("p", { staticClass: "text-right text-xs clear-both" }, [
                    _c(
                      "span",
                      {
                        class: {
                          "text-danger":
                            _vm.otherEmailLength > _vm.maxInputLength100,
                          "text-success":
                            _vm.otherEmailLength <= _vm.maxInputLength100
                        }
                      },
                      [_vm._v(_vm._s(_vm.otherEmailLength))]
                    ),
                    _vm._v(
                      " /" +
                        _vm._s(_vm.maxInputLength100) +
                        " " +
                        _vm._s(
                          this.$t("resorts.touristic-objects.form.maxChars")
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("label", { staticClass: "text-sm mt-1" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.website")))
      ]),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          type: "text",
          value: _vm.translation.web_link || "",
          required: "",
          disabled: _vm.disabled
        },
        model: {
          value: _vm.translation.web_link,
          callback: function($$v) {
            _vm.$set(_vm.translation, "web_link", $$v)
          },
          expression: "translation.web_link"
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.webLength > _vm.maxInputLength65k,
                  "text-success": _vm.webLength <= _vm.maxInputLength65k
                }
              },
              [_vm._v(_vm._s(_vm.webLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength65k) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("label", { staticClass: "text-sm" }, [
        _vm._v(
          _vm._s(this.$t("resorts.touristic-objects.form.reservationLink"))
        )
      ]),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          type: "text",
          value: _vm.translation.reservation || "",
          required: "",
          disabled: _vm.disabled
        },
        model: {
          value: _vm.translation.reservation_link,
          callback: function($$v) {
            _vm.$set(_vm.translation, "reservation_link", $$v)
          },
          expression: "translation.reservation_link"
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.reservationLength > _vm.maxInputLength65k,
                  "text-success": _vm.reservationLength <= _vm.maxInputLength65k
                }
              },
              [_vm._v(_vm._s(_vm.reservationLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength65k) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.ratesTitle")))
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.translation.rates,
            expression: "translation.rates"
          }
        ],
        staticClass: "w-full h-24 p-1 rounded resize-none border-grey-light",
        attrs: { required: "", disabled: _vm.disabled },
        domProps: { value: _vm.translation.rates },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.translation, "rates", $event.target.value)
          }
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.ratesLength > _vm.maxInputLength65k,
                  "text-success": _vm.ratesLength <= _vm.maxInputLength65k
                }
              },
              [_vm._v(_vm._s(_vm.ratesLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength65k) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [
        _vm._v(
          _vm._s(this.$t("resorts.touristic-objects.form.descriptionTitle"))
        )
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.translation.description,
            expression: "translation.description"
          }
        ],
        staticClass: "w-full h-24 p-1 rounded resize-none border-grey-light",
        attrs: { disabled: _vm.disabled },
        domProps: { value: _vm.translation.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.translation, "description", $event.target.value)
          }
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.descriptionLength > _vm.maxInputLength65k,
                  "text-success": _vm.descriptionLength <= _vm.maxInputLength65k
                }
              },
              [_vm._v(_vm._s(_vm.descriptionLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength65k) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(this.$t("resorts.touristic-objects.form.scheduleTitle")))
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.translation.schedule,
            expression: "translation.schedule"
          }
        ],
        staticClass:
          "w-full w-full h-24 p-1 rounded resize-none border-grey-light",
        attrs: { required: "", disabled: _vm.disabled },
        domProps: { value: _vm.translation.schedule },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.translation, "schedule", $event.target.value)
          }
        }
      }),
      !_vm.disabled
        ? _c("p", { staticClass: "text-right text-xs" }, [
            _c(
              "span",
              {
                class: {
                  "text-danger": _vm.scheduleLength > _vm.maxInputLength65k,
                  "text-success": _vm.scheduleLength <= _vm.maxInputLength65k
                }
              },
              [_vm._v(_vm._s(_vm.scheduleLength))]
            ),
            _vm._v(
              " /" +
                _vm._s(_vm.maxInputLength65k) +
                " " +
                _vm._s(this.$t("resorts.touristic-objects.form.maxChars")) +
                " "
            )
          ])
        : _vm._e(),
      _c("h6", { staticClass: "mt-4" }, [_vm._v("Tags")]),
      !_vm.disabled
        ? _c("div", [
            _c(
              "div",
              { staticClass: "mb-5 clear-both" },
              [
                _c("vs-input", {
                  attrs: {
                    label: this.$t("resorts.touristic-objects.form.services")
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addServiceChip()
                    },
                    blur: function($event) {
                      return _vm.addServiceChip()
                    }
                  },
                  model: {
                    value: _vm.serviceValue,
                    callback: function($$v) {
                      _vm.serviceValue = $$v
                    },
                    expression: "serviceValue"
                  }
                }),
                _vm._l(_vm.translation.services_tags, function(tag, index) {
                  return _c(
                    "vs-chip",
                    {
                      key: tag,
                      staticClass: " mt-2 rounded-md chip",
                      attrs: { color: "primary", closable: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeServiceTag(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-5 clear-both" },
              [
                _c("vs-input", {
                  attrs: {
                    label: this.$t("resorts.touristic-objects.form.equipments")
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addServiceChip()
                    },
                    blur: function($event) {
                      return _vm.addEquipmentChip()
                    }
                  },
                  model: {
                    value: _vm.equipmentValue,
                    callback: function($$v) {
                      _vm.equipmentValue = $$v
                    },
                    expression: "equipmentValue"
                  }
                }),
                _vm._l(_vm.translation.equipments_tags, function(tag, index) {
                  return _c(
                    "vs-chip",
                    {
                      key: tag,
                      staticClass: " mt-2 rounded-md chip",
                      attrs: { color: "primary", closable: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeEquipmentTag(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-5 clear-both" },
              [
                _c("vs-input", {
                  attrs: {
                    label: this.$t("resorts.touristic-objects.form.comfort")
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addServiceChip()
                    },
                    blur: function($event) {
                      return _vm.addComfortChip()
                    }
                  },
                  model: {
                    value: _vm.comfortValue,
                    callback: function($$v) {
                      _vm.comfortValue = $$v
                    },
                    expression: "comfortValue"
                  }
                }),
                _vm._l(_vm.translation.comfort_tags, function(tag, index) {
                  return _c(
                    "vs-chip",
                    {
                      key: tag,
                      staticClass: " mt-2 rounded-md chip",
                      attrs: { color: "primary", closable: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeComfortTag(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-5 clear-both" },
              [
                _c("vs-input", {
                  attrs: {
                    label: this.$t(
                      "resorts.touristic-objects.form.accessibility"
                    )
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addServiceChip()
                    },
                    blur: function($event) {
                      return _vm.addAccessibilityChip()
                    }
                  },
                  model: {
                    value: _vm.accessibilityValue,
                    callback: function($$v) {
                      _vm.accessibilityValue = $$v
                    },
                    expression: "accessibilityValue"
                  }
                }),
                _vm._l(_vm.translation.accessibility_tags, function(
                  tag,
                  index
                ) {
                  return _c(
                    "vs-chip",
                    {
                      key: tag,
                      staticClass: " mt-2 rounded-md chip",
                      attrs: { color: "primary", closable: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeAccessibilityTag(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-5 clear-both" },
              [
                _c("vs-input", {
                  attrs: {
                    label: this.$t("resorts.touristic-objects.form.payments")
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addServiceChip()
                    },
                    blur: function($event) {
                      return _vm.addPaymentChip()
                    }
                  },
                  model: {
                    value: _vm.paymentsValue,
                    callback: function($$v) {
                      _vm.paymentsValue = $$v
                    },
                    expression: "paymentsValue"
                  }
                }),
                _vm._l(_vm.translation.payments_mode_tags, function(
                  tag,
                  index
                ) {
                  return _c(
                    "vs-chip",
                    {
                      key: tag,
                      staticClass: " mt-2 rounded-md chip",
                      attrs: { color: "primary", closable: "" },
                      on: {
                        click: function($event) {
                          return _vm.removePaymentTag(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.disabled
        ? _c("div", [
            _vm.translation.services_tags.length > 0
              ? _c("div", { staticClass: "clear-both " }, [
                  _c("label", { staticClass: "text-sm mt-4" }, [
                    _vm._v("Services")
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.translation.services_tags, function(tag) {
                      return _c(
                        "vs-chip",
                        {
                          key: tag,
                          staticClass: " mt-2 rounded-md chip",
                          attrs: { color: "primary" }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _vm.translation.equipments_tags.length > 0
              ? _c("div", { staticClass: "clear-both " }, [
                  _c("label", { staticClass: "text-sm mt-4" }, [
                    _vm._v("Equipments")
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.translation.equipments_tags, function(tag) {
                      return _c(
                        "vs-chip",
                        {
                          key: tag,
                          staticClass: " mt-2 rounded-md chip",
                          attrs: { color: "primary" }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _vm.translation.comfort_tags.length > 0
              ? _c("div", { staticClass: "clear-both" }, [
                  _c("label", { staticClass: "text-sm mt-4" }, [
                    _vm._v("Comfort")
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.translation.comfort_tags, function(tag) {
                      return _c(
                        "vs-chip",
                        {
                          key: tag,
                          staticClass: " mt-2 rounded-md chip",
                          attrs: { color: "primary" }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _vm.translation.accessibility_tags.length > 0
              ? _c("div", { staticClass: "clear-both" }, [
                  _c("label", { staticClass: "text-sm mt-4" }, [
                    _vm._v("Accessibility")
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.translation.accessibility_tags, function(tag) {
                      return _c(
                        "vs-chip",
                        {
                          key: tag,
                          staticClass: " mt-2 rounded-md chip",
                          attrs: { color: "primary" }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _vm.translation.payments_mode_tags.length > 0
              ? _c("div", { staticClass: "clear-both" }, [
                  _c("label", { staticClass: "text-sm mt-4" }, [
                    _vm._v("Payments")
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.translation.payments_mode_tags, function(tag) {
                      return _c(
                        "vs-chip",
                        {
                          key: tag,
                          staticClass: " mt-2 rounded-md chip",
                          attrs: { color: "primary" }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }