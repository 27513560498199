<template>
    <div class="flex flex-col vs-con-input-label">
        <label v-if="label" class="vs-input--label">{{ label }}</label>
        <flat-pickr 
            :placeholder="placeholder" 
            v-model="date"
            :config="{
                altInput: true,
                altFormat: withTime ? 'Y-m-d H:i' : 'Y-m-d',
                altInputClass: 'vs-inputx vs-input--input normal',
                wrap: false,
                enableTime: withTime,
                time_24hr: true,
                defaultDate: defaultDate ? defaultDate.toISO() : null,
                minDate: minDate ? minDate.toISO() : null,
                maxDate: maxDate ? maxDate.toISO() : null,
                minuteIncrement: 1
            }"
        />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import flatPickr from 'vue-flatpickr-component';
import LabelForm from '@/modules/Shared/Components/form/LabelForm.vue';

export default {
    name: 'datepicker',
    components: {
        flatPickr,
        LabelForm,
    },
    data() {
        return {
            date: null
        }
    },
    props: {
        value: {
            type: DateTime,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        withTime: {
            type: Boolean,
            default: false
        },
        defaultDate: {
            type: DateTime,
            default: null
        },
        minDate: {
            type: DateTime,
            default: null
        },
        maxDate: {
            type: DateTime,
            default: null
        }
    },
    computed: {
        DateTime() {
            return DateTime
        },
    },
    watch: {
        date() {
            this.$emit('input', DateTime.fromFormat(this.date, this.withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'))
        },
        // value() {
        //     this.date = this.value ? this.value.toFormat(this.withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd') : null
        // },
        'value': {
          deep: true,
          immediate: true,
          async handler() {
            this.date = this.value ? this.value.toFormat(this.withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd') : null
          }
      }
    },
    // created() {
    //     console.log(this.value)
    //     this.date = this.value ? this.value.toFormat(this.withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd') : null
    // },
}
</script>