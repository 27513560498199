var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _vm.mapCoordinates && _vm.mapCoordinates.length > 0
        ? _c("div", { staticClass: "flex flex-row mt-4 gap-2" }, [
            _c("label", { staticClass: "text-sm w-2/12 " }, [
              _vm._v(_vm._s(_vm.$t("geofences.add-geofences.latitude")) + " ")
            ]),
            _c("label", { staticClass: "text-sm w-2/12" }, [
              _vm._v(_vm._s(_vm.$t("geofences.add-geofences.longitude")) + " ")
            ]),
            _c("label", { staticClass: "text-sm w-2/12" }, [
              _vm._v(_vm._s(_vm.$t("resorts.touristic-objects.form.height")))
            ])
          ])
        : _vm._e(),
      _vm._l(this.mapCoordinates, function(option, index) {
        return _c(
          "div",
          { key: index, staticClass: "flex flex-row gap-2" },
          [
            _c("vs-input", {
              staticClass: "w-2/12",
              attrs: {
                type: "number",
                value: option.lat || 0,
                disabled: _vm.disabled
              },
              model: {
                value: option.lat,
                callback: function($$v) {
                  _vm.$set(option, "lat", $$v)
                },
                expression: "option.lat"
              }
            }),
            _c("vs-input", {
              staticClass: "w-2/12 ",
              attrs: {
                type: "number",
                value: option.lng || 0,
                disabled: _vm.disabled
              },
              model: {
                value: option.lng,
                callback: function($$v) {
                  _vm.$set(option, "lng", $$v)
                },
                expression: "option.lng"
              }
            }),
            _c("vs-input", {
              staticClass: "w-2/12 ",
              attrs: {
                type: "number",
                value: option.height || 0,
                disabled: _vm.disabled
              },
              model: {
                value: option.height,
                callback: function($$v) {
                  _vm.$set(option, "height", $$v)
                },
                expression: "option.height"
              }
            }),
            !_vm.disabled
              ? _c(
                  "vs-button",
                  {
                    staticClass: "px-2 pt-1 pb-0 mb-5 mr-2 rounded-lg ",
                    attrs: { type: "border", color: "rgb(255, 0, 0)" },
                    on: {
                      click: function($event) {
                        return _vm.deleteRow(index)
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: "TrashIcon",
                        svgClasses: " align-middle w-6"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "w-2/3 mt-5" },
        [
          !_vm.disabled
            ? _c(
                "vs-button",
                {
                  staticClass: "rounded-lg xs:w-full sm:w-auto text-primary",
                  attrs: { type: "border" },
                  on: { click: _vm.add }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.$t("resorts.touristic-objects.form.add_point")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }